:root{
	--bg: #FFFFFF;
	--headerbg: #FFFFFF;
	--blue:#2B417C;
	--orange:#f5801c;
	--contenttext:#ffffff;
	--headertext:#ffffff;
	
}

body{
	background-color: var(--blue);
}


div.contentarea img			{
					width:80%;
				}
div.border {	
					background-color: var(--bg);	
					margin: 1em auto 1em auto;
					border-radius: 1em;
					min-width: 20em;
					width: 95%;
					padding-top:2em;
					max-width:100em;
				}

div.header {
					background-color:var(--headerbg); 
					color: var(--headertext);
					width:100%;
					display:table;
					table-layout:fixed;
				}


div.contentarea{
					margin-top:1em;
					
				}
div.content>*{
					color: var(--contenttext);
					font-family: sans-serif;
					padding: 0em 2em 0em 2em;
				}
				
div.content div.twin{
		display:flex;
		flex-wrap:wrap;
		justify-content:center;
}
div.twin > div.spacer{
		width:3em;
}


div.footer{
					padding-left: 1em;
					padding-top:0.3em;
					bottom:0em;
					height: 2em;
					background-color:var(--headerbg); 
					padding-right: 1em;
					color: var(--blue);
					border-bottom-right-radius: 1em;
					border-bottom-left-radius: 1em;
					/*font-size:70%;*/	
					text-align: center;	
					}
div.footer > * {
						position:relative;
						vertical-align: middle;		
					}
				
span.textheader{
					font-size:110%;
					font-weight: bold;
}	
div.contenthdr{
					position:relative;
					background-color: var(--orange);
					padding:0.1em;			
					margin-bottom:0.5em;			
					border-radius: 1em 1em 0em 0em;
					font-size:150%;
					font-family: sans-serif;
					font-variant: small-caps;
					font-weight:bold;
				}

div.contenthdrstd{
					color:white;				
}
				
div.contenthdremph{
					color:var(--blue);
}


div.content{
					border-radius: 1em;
					background-color:var(--orange);
					margin: 0em 1em 1em 1em;
					padding:1em;
					min-height: 8em;
				}

span.cycle-slideshow{
	display:none;
}

span.logo img{
	max-width:97%; 
	max-height:15em;
}
span.logo{
	padding-left:1em;
}

@media only screen and (max-width: 1292px){ /*cheap IE fix....*/
span.cycle-slideshow{
	width:100%;
}
}

@media only screen and (min-width: 30em) and (min-device-width:30em){
span.cycle-slideshow{
		display:table-cell;
		float:right;
	}			
span.logo{
		display:table-cell;
		width:48%;
	}
}

span.cycle-slideshow > img{
					max-width:100%;
					max-height:15em;
					
					overflow:hidden;
					float:right;
				}

div.nav{
	margin:0.5em 1em 0em 0em;
	display:block;	
	height: 150px;	/*Android Hack*/
	height: 5vw;
}
div.nav > a{
	float:right;
}
div.nav img{
	width:5vw;
	min-width:2em;
}
